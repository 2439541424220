.match-summary-box-container {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 20px;

  @include mq($until: tablet) {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.match-summary-box-column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  margin-left: 32px;
}

.match-summary-box-wrapper {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
}

.match-summary-box-wrapper-first {
  &:after {
    content: "";
    width: 38px;
    height: 100%;
    border-top: 1px solid;
    border-right: 1px solid;
    position: absolute;
    right: -22px;
    top: 50%;
    margin-top: 1px;


    @include mq($until: tablet) {
      width: 20px;
      right: -8px;
    }
  }
}

.match-summary-box-wrapper-second {
  &:after {
    content: "";
    width: 38px;
    height: 90%;
    border-bottom: 1px solid;
    border-right: 1px solid;
    position: absolute;
    right: -22px;
    bottom: 50%;
    margin-top: 1px;


    @include mq($until: tablet) {
      width: 20px;
      right: -8px;
    }
  }
}

.match-summary-box-wrapper-before {
  &:before {
    content: "";
    width: 26px;
    height: 100%;
    border-top: 1px solid;
    position: absolute;
    left: -10px;
    top: 50%;
    margin-top: 1px;


    @include mq($until: tablet) {
      width: 20px;
      left: -24px;
    }
  }
}


.match-summary-box {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 400px;
  border-top-left-radius: $border-radius-base;
  border-top-right-radius: $border-radius-base;
  box-shadow: $shadow-base;
  background: $white;
  padding: 16px;
  margin: 16px;

  button {
    position: absolute;
    top: -20px;
    right: -20px;
    z-index: 99;
  }

  @include mq($until: tablet) {
    width: 320px;
    padding: 8px 16px;
    margin-left: 0px;
  }

  &-first-column {
    @include mq($until: tablet) {
      margin-left: 15px;
    }
  }

  &-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    color: $spanish-grey;
  }

  .ant-avatar {
    margin-right: 8px;
  }

  &-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-scores {
    display: flex;
  }

  &-score {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    margin: 4px;
    padding: 4px;
    background: $white-smoke;
    font-weight: bold;

    &--title {
      background: none;
      padding: 0;
      margin: 0;
      text-align: center;
      width: 38px;
      color: $spanish-grey;
      font-weight: normal;
    }
  }

  hr {
    width: 100%;
    background: $primary-color;
    height: 1px;
    border: none;
  }
}

.matches-pagination {
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 16px 24px;
  margin: 16px;

  .ant-pagination {
    text-align: center;
  }
}
