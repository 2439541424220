/* flex containers */

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.flex-horizontal {
  display: flex;
}

.flex-horizontal-center {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.success-button.ant-btn {
  border-color: $malachite-green;
  color: $malachite-green;
}
.success-button.ant-btn:hover,
.success-button.ant-btn:focus,
.success-button.ant-btn:active {
  border-color: rgba($malachite-green, 0.6);
  color: rgba($malachite-green, 0.6);
}

.danger-button.ant-btn {
  border-color: $primaryRed;
  color: $primaryRed;
}
.danger-button.ant-btn:hover,
.danger-button.ant-btn:focus,
.danger-button.ant-btn:active {
  border-color: rgba($primaryRed, 0.6);
  color: rgba($primaryRed, 0.6);
}
