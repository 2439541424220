/* fullscreen component */

.fullscreen {
  display: flex;
  height: 100vh;

  @include mq($until: tablet) {
    flex-direction: column;
  }

  &__logo {
    max-width: 200px;
    margin-bottom: 20px;
  }

  &__image {
    flex: 1;
    background: url('/assets/images/fullscreen-bg.jpg') no-repeat center;
    background-size: cover;

    @include mq($until: tablet) {
      display: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;

    &__form {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;

      .custom-form {
        @include mq($until: tablet) {
          background: white;
          border-radius: 5px;
          padding: 20px;
        }
      }
    }

    &__help-text {
      padding: 20px;

      @include mq($until: tablet) {
        color: white;
      }
    }

    @include mq($until: tablet) {
      background: url('/assets/images/login-min.png') no-repeat center;
      background-size: cover;
    }
  }
}
