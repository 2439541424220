/* content header */

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  &--single {
    justify-content: flex-end;
  }

  input {
    max-width: 300px;
  }

  @include mq($until: mobileLandscape) {
    flex-direction: column-reverse;
    input {
      margin-top: 10px;
      max-width: 100%;
    }

    button {
      width: 100%;
    }
  }
}
