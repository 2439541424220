/* ==========================================================================
   #GLOBAL
   ========================================================================== */

/* Color */
$elf-green: #1A936F;
$malachite-green: #09ED68;
$astronaut-blue: #114B5F;
$de-york: #88D498;
$surf-crest: #C6DABF;
$half-pearl-lusta: #F3E9D2;
$floral-white: #FFFAEE;
$white: #FFFFFF;
$white-smoke: #F5F5F5;
$spanish-grey: #979797;
$light-grey: #CCCCCC;
$light-grey2: #C4C4C4;
$dark-grey: #424242;
$black: #000000;
$primary-blue: #3366FF;
$primary-orange: #FF7052;

$primary-color: $elf-green;

$brand-color: $elf-green;
$primaryBlack: #2b2825;
$primaryRed: #d80f15;
$warning: #f1c40f;

$border-radius-base: 10px;
$shadow-base: 0px -4px 20px rgba(37, 38, 94, 0.1);;
