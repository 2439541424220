/* #Base Rules for common HTML elements
   ========================================================================== */

body {
  font-size: 14px;
}

a:focus {
  text-decoration: none;
}
