/* buttons-area */

.buttons-area {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 24px;

  button + button {
    margin-left: 16px;
  }
}
