/* custom-form */

.custom-form {
  min-width: 300px;

  &__item {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 15px;
  }

  &__label {
    flex: 2;
    text-align: left;
    max-width: 150px;
  }

  &__left-align-label {
    text-align: left;
  }

  &__control {
    flex: 3;

    &--full {
      flex-shrink: 0;
      flex-basis: 100%;
    }
  }

  &__divider {
    margin: 4px 0;
  }

  &__label-description {
    flex: 3;
    font-size: 10px;
    margin: 0;
    padding: 0;
  }

  h3 {

  }
}
