/* container */

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px 30px;
  min-height: calc(100vh - 110px);

  nz-alert {
    margin-bottom: 10px;
  }

  @include mq($until: tablet) {
    padding: 20px 10px;
  }

  &.recipe-create {
    .ant-divider-horizontal.ant-divider-with-text {
      color: $brand-color;
    }
  }
}
