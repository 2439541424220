/* dashboard */

.dashboard {
  &__statistic-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;

    h3 {
      font-size: 30px;
    }

    i {
      font-size: 30px;
    }
  }
}
