/* table */

.table {
  &__clickable-row {
    cursor: pointer;
  }

  &__thumb {
    width: 100%;
    max-width: 40px;
    max-height: 40px;
    height: auto;
  }

  .ant-skeleton {
    margin-bottom: 20px;
  }
}
