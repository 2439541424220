/* text utilities, font weight, alignment etc. */

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}


.flex {
  display: flex;
  align-items: center;
}
