/* ant */

.ant {
  &-layout {
    .header {
      display: flex;
      justify-content: flex-end;

      @include mq($until: desktop) {
        justify-content: space-between;
      }

      .logo {
        display: none;

        @include mq($until: desktop) {
          display: flex;
        }
      }

      .user-menu {
        display: block;

        @include mq($until: desktop) {
          display: none;
        }

        i {
          margin-right: 10px;
        }
      }

      .user-mobile-menu {
        display: none;

        i {
          font-size: 20px;
          margin-right: 10px;
        }

        @include mq($until: desktop) {
          display: block;
        }
      }
    }

    .content {
      min-height: calc(100vh - 64px);
    }

    .sider {
      display: block;

      @include mq($until: desktop) {
        display: none;
      }
    }
  }

  &-statistic-content-prefix {
    margin-right: 10px;
  }

  &-table {
    button + button {
      margin-left: 8px;
    }

    .cdk-drag-preview {
      display: table;
    }

    .cdk-drag-placeholder {
      opacity: 0;
    }
  }

  &-badge-status-dot {
    width: 10px;
    height: 10px;
  }

  &-typography {
    margin: 0 10px 0;
  }
}

.layout.ant-layout {
  min-height: 100vh;
}

.header.ant-layout-header {
  z-index: 19;
  display: flex;
  align-items: center;
  width: 100%;

  @include mq($until: tablet) {
    padding: 0 20px;
  }
}

.user-menu-overlay {
  .anticon {
    margin-right: 10px;
  }
}
