/* logo */

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 96px;
  width: 200px;
  transition: width 0.2s cubic-bezier(0.25, 0, 0.15, 1);

  img {
    cursor: pointer;
    display: inline-block;
    max-height: 55px;

    @include mq($until: desktop) {
      max-height: 40px;
    }
  }
}
